const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "预案名称",
                prop: "YAMC",
            }, {
                label: "预警等级",
                prop: "YJDJ",
                width: "120px",
                transList: {
                    "0": "一级预警",
                    "1": "二级预警",
                    "2": "三级预警",
                    "3": "四级预警",
                },
                colorList: {
                    '0': "#ff4c4c",
                    '1': "#ffb508",
                    '2': "#ffff00",
                    '3': "#2EA7E0"
                },
            }, {
                label: "编写时间",
                prop: "CJSJ",
                width: "120px",
                substr: true
            }, {
                label: "备注说明",
                prop: "MS",
                width: "220px"
            }, {
                label: "预案文档",
                prop: "WJMC",
                textFile: true,
                URL: "WJLJ",
            }],
            // 操作列
            // {
            //     isShow: true,
            //     btnType: 'icon',
            //     operateType: 'columnRead',
            //     title: '查看',
            //     icon: 'el-icon-view',
            //     color: 'green',
            //     showMethod: 'showReadBtn',
            // },
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnEdit',
                    title: '修改',
                    icon: 'el-icon-edit',
                    color: 'green',
                    showMethod: 'showEditBtn',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnDelete',
                    title: '删除',
                    icon: 'el-icon-delete',
                    color: 'red',
                    showMethod: 'showDelBtn',
                }],
                width: 150,
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        selectData: [{
            label: '预警等级',
            clearable: true,
            placeholder: "请选择预警等级",
            list: [],
            optionLabel: 'NAME',
            optionValue: 'CODE',
            value: 'YJDJ',
            operateType: 'search',
            isShow: true
        }],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建',
            round: true,
            backColor: '#28ccd9',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        },]
    }
};
export { selfData };