import alertInfo from '@comp/alert_info';
import basicUpload from '@comp/basic_upload';
import { mapActions } from 'vuex';
export default {
    components: {
        alertInfo,
        basicUpload
    },
    data() {
        return {
            tableGroupAttributes: {},
            multipleSelection: [],
            tabheight: null,
            searchData: [],
            dateSearchData: [],
            total: 10, // 每页显示几条数据
        };
    },
    mounted() {
        this.handleDataAll(this.selfData, this.commonData);
        this.tableGroupAttributes = this.selfData;
        let search = this.tableGroupAttributes.snSearch.selectData || [];
        if (search.length > 0) {
            search.forEach(item => {
                let type = item.value;
                this.searchData.push({ type: type, value: '' });
            });
        }
        let dateSearch = this.tableGroupAttributes.snSearch.dateData || [];
        if (dateSearch.length > 0) {
            dateSearch.forEach(ele => {
                let type = ele.value;
                this.dateSearchData.push({ type: type, value: '' });
            });
        }
        this.getTableData();
    },
    methods: {
        ...mapActions([

        ]),
        handleDataAll(self, common) {
            Object.keys(common).forEach(key => {
                if (self.hasOwnProperty(key)) {
                    if (typeof common[key] !== 'object') return false;
                    if (key === 'snButton') return false;
                    this.handleDataAll(self[key], common[key]);
                } else {
                    self[key] = common[key];
                }
            });
        },
        handleChange(type, value) {
            console.log("type, value",type, value)
            switch (type) {
                case 'search': // 搜索
                    this[value[1]] = value[0];
                    this.searchData.forEach(item => {
                        if (item.type === value[1]) {
                            item.value = value[0];
                        }
                    });
                    this.dateSearchData.forEach(ele => {
                        if (ele.type === value[1]) {
                            ele.value = value[0];
                        }
                    });
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'total': // 每页的条数
                    this[value[1]] = value[0];
                    this.tableGroupAttributes.snPage.tiaoshu = value[0];
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'page': // 当前是第几页
                    this.tableGroupAttributes.snPage.currentPage = value[0];
                    this.getTableData();
                    break;
                case 'searchBtn': // 点击查询按钮
                    this.getTableData();
                    break;
                case 'treesearch': // 点击查询按钮
                    if (value[1] === 'YJFX') {
                        // 研究方向
                        this.tasktype = value[0];
                    }
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getTableData();
                    break;
                case 'buttonDel': // 批量删除按钮
                    this.delSome();
                    break;
                case 'buttonNew': // 新建按钮
                    this.toEditPage('', '0');
                    break;
                case 'buttonExport': // 导出按钮
                    this.exportData();
                    break;
                case 'columnRead': // 查看
                    this.toEditPage(value, '2');
                    break;
                case 'columnEdit': // 编辑按钮
                    this.toEditPage(value, '1');
                    break;
                case 'columnDelete': // 删除按钮
                    this.deleteData(value[0].Id ? value[0].Id : value[0].ID, value[0]); // TODO id
                    break;
                case 'selection': // 表格选择框
                    this.selectData(value[0]);
                    break;
                case 'columnConfig': // 配置
                    this.columnConfigClick(value, '0');
                    break;
                case 'columnPause': // 暂停
                    this.columnPauseClick(value);
                    break;
                case 'columnStart': // 开始
                    this.columnStartClick(value);
                    break;
                default:
                    break;
            }
        },
        toEditPage(value, type) {
            let id = value && value.length > 0 ? value[0].Id ? value[0].Id : value[0].ID : '';

            this.$router.push({
                name: this.infoPage,
                query: {
                    type: type,
                    id: id,
                    data: JSON.stringify(value[0])
                },
            });
        },
        async exportData() {
            if (!this.exportFunId) return;
            let exportFunId = this.exportFunId;
            let exportName = this.exportName ? this.exportName : '表格';
            request();

            console.log("this.exportFunId",this.exportFunId)
            console.log("this.exportName",this.exportName)

            function request() {
                const req = new XMLHttpRequest();
                let url = window.REQUEST_URL + 'EarlyWarn/ExportYjData';
                let funId = exportFunId; // String 约定的id：预警预案:yj001；预警对象设置:yj002；预警配置:yj003；预警处置:yj004
                let exportUrl = `${url}?funId=${funId}`;
                req.open('GET', exportUrl, true);
                req.responseType = 'blob';
                req.setRequestHeader('Content-Type', 'application/json');
                req.setRequestHeader('token', localStorage.accessToken);
                req.onload = function() {
                    const data = req.response;
                    const blob = new Blob([data]);
                    const blobUrl = window.URL.createObjectURL(blob);
                    downloadFile(blobUrl);
                };
                req.send('');
            }

            function downloadFile(blobUrl) {
                console.log(blobUrl, 'blobUrl');
                const a = document.createElement('a');
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', blobUrl);
                let filename = `${exportName}.xls`;
                a.setAttribute('download', filename);
                a.click();
                // document.body.removeChild(a);
            }
        },

        deleteData(id, value) {
            let _this = this;
            let checkBox = id;
            _this
                .$confirm('是否永久删除该条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            _this.delData(checkBox, done, instance, value);
                        } else {
                            done();
                        }
                    }
                })
                .then(() => {})
                .catch(() => {
                    _this.$message({
                        message: '已取消删除',
                        type: 'info'
                    });
                });
        },
        delSome() {
            let checkBox = [];
            this.multipleSelection.forEach(function(o) {
                checkBox.push(o.ID ? o.ID : o.Id); // TODO id
            });
            if (checkBox.length === 0) {
                this.$message({
                    message: '请选择数据项！',
                    type: 'warning'
                });
            } else {
                checkBox = checkBox.join();
                this.deleteData(checkBox);
            }
        },
        delOver(res, done) {
            if (res.IsSuccess) {
                done();
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.tableGroupAttributes.snPage.currentPage = 1;
                this.getTableData();
            } else {
                this.$message({
                    message: '删除失败',
                    type: 'error'
                });
            }
        },
        selectData(value) {
            this.multipleSelection = [];
            if (value && value.length > 0) {
                value.forEach(item => {
                    this.multipleSelection.push(item);
                });
            }
        }
    }
};