<template>
    <div class="warning-plan-grid">
        <sn-table-group
            class="table"
            :tableGroupAttributes="tableGroupAttributes"
            :commonMethods="commonMethods"
            @handleChange="handleChange"
        ></sn-table-group>
    </div>
</template>
<script>
import { commonData } from "../warning_common/sn-table-group-common";
import { selfData } from "./model/sn-table-group";
import commonMethodMixin from "../warning_common/commonMethodMixin";
import { mapActions } from "vuex";
import { commonMethods } from "./model/table-methods";
export default {
    name: "warning_plan_grid",
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            commonMethods,
            selfData,
            infoPage: "warning_plan_form",
            // 筛选项
            keyword: "", // 关键字
            yjdjValue: "", // 对象类型选择的值
            exportFunId: "yj001",
            exportName: "预警预案",
        };
    },
    mounted() {
        this.getYjdjList();
    },
    methods: {
        ...mapActions([
            "GetYjyaDataList", // 获取预警预案数据
            "GetEnumIdList", // 获取预警等级的枚举
            "DeleteYjyaData", // 删除预警预案数据
        ]),
        async getTableData() {
            // 处理筛选
            this.yjdjValue = "";
            if (this.searchData && this.searchData.length > 0) {
                this.searchData.forEach((item, index) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        if (item.type === "YJDJ") {
                            this.yjdjValue = item.value;
                        }
                    }
                });
            }

            this.tableGroupAttributes.loading = true;
            let res = await this.GetYjyaDataList({
                keyWords: this.keyword, // String 关键字；预案名称
                YJDJ: this.yjdjValue, // String 预警等级，传0,1,2,3
                pageIndex: this.tableGroupAttributes.snPage.currentPage, // Int 页数
                pageSize: this.tableGroupAttributes.snPage.tiaoshu, // Int 页面数据量
            });
            this.tableGroupAttributes.snTable.table.data =
                res && res.DATA ? res.DATA : [];
            this.tableGroupAttributes.snPage.count =
                res && res.TOTAL ? Number(res.TOTAL) : 0;
            this.tableGroupAttributes.loading = false;
        },
        // 获取预警等级列表
        async getYjdjList() {
            this.tableGroupAttributes.snSearch.selectData[0].list = [];
            let result = await this.GetEnumIdList({
                enumId: "212cb2aa-748a-11ea-bd8d-000c2977b7fd",
            });
            if (result && result.length > 0) {
                this.tableGroupAttributes.snSearch.selectData[0].list = result;
            }
        },
        // 删除数据
        async delData(id, done, instance) {
            let res = await this.DeleteYjyaData({
                ids: id,
            });
            this.delOver(res, done);
        },
        delOver(res, done) {
            if (res.ISSUCCESS) {
                done();
                this.$message({
                    message: res.RESULTDESCRIPTION,
                    type: "success",
                });
                // this.tableGroupAttributes.snPage.currentPage = 1;
                this.getTableData();
            } else {
                this.$message({
                    message: res.RESULTDESCRIPTION,
                    type: "error",
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.warning-plan-grid {
    width: 100%;
    height: 100%;
    .table {
        width: 100%;
        height: 100%;
    }
}
</style>
