import { findLastIndexOf } from "xe-utils";

const commonMethods = {
    // "ISEXISTS": "0",//，是否存在；0不存在可删除，1存在不可删除
    // 查看
    showReadBtn(row) {
        return true;
    },
    // 修改
    showEditBtn(row) {
        return true;
    },
    // 删除
    showDelBtn(row) {
        if (Number(row.ISEXISTS) === 1) {
            return false;
        } else {
            return true;
        }
    },
};
export { commonMethods };